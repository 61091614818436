<template lang="pug">
  div
    base-snackbar(
      :errors="errors"
    )
    v-row
      v-col(cols="12")
        v-text-field(
          label="Sow Number"
          v-model="sowNumber"
          :error-messages="errors.sow_number"
        )
      v-col(cols="12")
        v-text-field(
          label="Service"
          v-model="service"
          type="date"
          :error-messages="errors.service"
        )
      v-col(cols="12")
        v-text-field(
          label="Farrowing"
          v-model="farrowing"
          :disabled="!service"
          type="date"
          :error-messages="errors.farrowing"
          :clearable="!weaning && farrowing"
        )
      v-col(cols="12")
        v-text-field(
          label="Weaning"
          v-model="weaning"
          type="date"
          :disabled="!farrowing"
          :error-messages="errors.weaning"
          :clearable="weaning"
        )
      v-col(cols="12")
        v-text-field(
          label="Piglets"
          v-model="piglets"
          type="number"
          :error-messages="errors.piglets"
        )
</template>
<script>
const inputVars = () => ({
  id: null,
  sowNumber: null,
  service: null,
  farrowing: null,
  weaning: null,
  customerId: null,
  customerForecastId: null,
  piglets: null,
})

// const sowPigRequestHandler = new VueRequestHandler(null, sowPigPostVarNames)

export default {
  name: 'CreateSowPig',
  props: {
    value: {
      type: Object,
      default: inputVars,
    },
    defaultValue: {
      type: Object,
      default: inputVars,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    const { value } = this
    this.populateFields(value)
  },
  data: () => ({
    ...inputVars(),
    // ...sowPigPostVars,
  }),
  watch: {
    id() {
      this.updateInputs()
    },
    sowNumber() {
      this.updateInputs()
    },
    service(value, lastValue) {
      this.updateInputs()
    },
    farrowing(value, lastValue) {
      if (value && (value <= this.service)) {
        this.$nextTick(() => { this.farrowing = lastValue })
        return
      }
      if (value && this.weaning && (value >= this.weaning)) {
        this.$nextTick(() => { this.farrowing = lastValue })
        return
      }
      this.updateInputs()
    },
    weaning(value, lastValue) {
      if (value && (value <= this.service)) {
        this.$nextTick(() => { this.weaning = lastValue })
        return
      }
      if (value && (value <= this.farrowing)) {
        this.$nextTick(() => { this.weaning = lastValue })
        return
      }
      this.updateInputs()
    },
    value: {
      handler(item) {
        this.populateFields(item)
      },
      deep: true,
    },
  },
  methods: {
    updateInputs() {
      const data = this.getInputFields()
      this.$emit('input', data)
    },
    populateFields(item) {
      Object.keys(item).forEach(key => {
        const key2 = key.snakeToCamelCase()
        if (this[key2] !== item[key]) this[key2] = item[key]
      })
    },
    // storeSowPig () {
    //   const repository = sowPigRepository.store
    //   sowPigRequestHandler.setVue(this)
    //   const { data } = this.getInputFields()
    //   
    //   sowPigRequestHandler.execute(repository, [data])
    // },
    getInputFields() {
      const { value } = this
      const data = Object.keys(inputVars())
        .reduce((result, key) => {
          result[key] = this[key]
          return result
        }, {})
      return { ...value, ...data }
    },
  },
}
</script>
